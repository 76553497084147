/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import { fetchAuthenticated, get } from 'services/fetch';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { emitEvent } from 'utils/events';
import ModalShowLORAInfo from 'components/modals/ModalShowLORAInfo';
import {
  getPositionIndicator,
  getEventName,
  isPositionValid,
  getStatus,
} from 'utils/helpers';

import {
  getThreshold,
  analyzeCoordinates
} from 'utils/position.helpers'


import L from 'leaflet';

import InfoCardField from './InfoCardField';
import InfoCard from './InfoCard';

import { EMERGENCY_LABELS, getEmergencyLabel } from 'utils/labels';

import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import './Map.css';

const expandedHeight = 700;

const EventsConfig = {
  lastEvent: {
    iconColor: 'blue',
    headerColor: '#548cc1',
  },
  lastEventGps: {
    iconColor: 'darkgray',
    headerColor: '#42475a',
  },
  lastEventLbsQuectel: {
    iconColor: 'blue',
    circle: {
      color: '#548cc1',
      radius: () => 600,
    },
    headerColor: '#548cc1',
  },
  lastEventLbsXglobal: {
    iconColor: 'blue',
    circle: {
      color: '#548cc1',
      radius: () => 600,
    },
    headerColor: '#548cc1',
  },
  lastEventLbsGoogle: {
    iconColor: 'purple',
    circle: {
      color: '#673E9A',
      radius: () => 600,
    },
    headerColor: '#673E9A',
  },
  lastEventLbsCellID: {
    iconColor: 'gray',
    circle: {
      color: '#8E96B0',
      radius: event => event.cellIdRange || 0,
    },
    headerColor: '#8E96B0',
  },
  lastEventLORA: {
    iconColor: 'green',
    circle: {
      color: '#07A39F',
      radius: () => 400,
    },
    headerColor: '#07A39F',
  },
  lastEventEseye: {
    iconColor: 'lightblue',
    circle: {
      color: '#37b0c9',
      radius: () => 600,
    },
    headerColor: '#37b0c9',
  },
  lastEventAlgar: {
    iconColor: 'lightblue',
    circle: {
      color: '#37b0c9',
      radius: () => 600,
    },
    headerColor: '#37b0c9',
  },
  'lastEventLbsPlus': {
    iconColor: 'LBSPlus',
    circle: {
      color: '#A330CC',
      radius: () => 50,
    },
    headerColor: '#A330CC',
  },
};

const getXGlobalEventNames = equipment => {
  const events = ['lastEventEseye', 'lastEventLbsCellID', 'lastEventLORA', 'lastEventAlgar'];
  const currentEventName = getEventName(equipment.lastEvent);
  if (currentEventName && events.indexOf(currentEventName) === -1) {
    events.push(currentEventName);
  }
  return events;
};

function Map(props) {
  const {
    classes,
    disablePopup,
    disableExpand,
    showXglobal,
    onEventClick,
    showAllFences,
    showCurrentEquipmentFences,
  } = props;
  const height = props.height || 400;
  const containerStyle = props.containerStyle || {};

  const [equipments, setEquipments] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [circles, setCircles] = useState([]);
  const [fences, setFences] = useState([]);
  const [isFitBoundsFinished, setIsFitBoundsFinished] = useState(false);
  const [fenceLayers, setFenceLayers] = useState([]);

  const [showInfo, setShowInfo] = useState(false);
  const [toggleMap, setToggleMap] = useState(false);
  const [mapHeight, setMapHeight] = useState(height);
  const [mapInstance, setMapInstance] = useState();
  const [currentEquipment, setCurrentEquipment] = useState({});
  const [currentHeaderColor, setCurrentHeaderColor] = useState(null);
  const [loraEventInfo, setLoraEventInfo] = useState({});
  const [modalShowLORAInfoVisible, setModalShowLORAInfoVisible] =
    useState(false);

  useEffect(() => {
    if (props.currentEquipment) {
      setCurrentEquipment(props.currentEquipment);
    }
  }, [props.currentEquipment]);

  useEffect(() => {
    if (props.equipments) {
      if (props.equipments.data) {
        setEquipments(props.equipments.data);
      } else {
        // only update when equipment changes
        if (JSON.stringify(props.equipments) !== JSON.stringify(equipments)) {
          setEquipments(props.equipments);
        }
      }
    }
  }, [props.equipments]);

  useEffect(() => {
    if (props.centerMapOn) {
      centerMap(
        props.centerMapOn.lastEvent.latitude,
        props.centerMapOn.lastEvent.longitude
      );
      setCurrentEquipment(props.centerMapOn);
      if (!disablePopup) {
        setShowInfo(true);
      }
      const _lastEvent = props.centerMapOn.lastEvent;
      if (!isPositionValid(_lastEvent)) {
        emitEvent('showSnack', { message: 'Posição inválida', type: 'error' });
      }
    }
  }, [props.centerMapOn]);

  const centerMap = (lat, long) => {
    mapInstance.setView(L.latLng(lat, long), 15, { animation: true });
  };
  // Shouw popup, set current equipment / current equipment-event
  const handleMarkClick = (equipment, equipmentEvent, e) => {
    // if (!('isLoraPosition' in equipmentEvent)) {
    // update current equipment
    setCurrentEquipment(equipment);

    // update current equipment-event if xglobal position is set
    if (showXglobal) {
      if (!onEventClick) {
        throw new Error('onEventClick not provided when showXglobal=true');
      }
      onEventClick(equipmentEvent);
    }

    // center map on click
    const latLng = e.target.getLatLng();
    centerMap(latLng.lat, latLng.lng);

    if (!disablePopup) {
      setShowInfo(true);
    }
    // }
    // else {
    //   try {
    //     if (
    //       'isValidPosition' in equipmentEvent &&
    //       equipmentEvent['isValidPosition']
    //     ) {
    //       let timestampStr = '';

    //       let date = equipmentEvent.timestamp.split('T')[0];
    //       let time = equipmentEvent.timestamp.split('T')[1].split('.')[0];

    //       timestampStr = `${date.split('-')[2]}/${date.split('-')[1]}/${
    //         date.split('-')[0]
    //       } ${time}`;
    //       setLoraEventInfo({
    //         timestamp: timestampStr,
    //         latlong: `${equipmentEvent.latitude} / ${equipmentEvent.longitude}`,
    //         signal: equipmentEvent.signal,
    //       });
    //     }

    //     setModalShowLORAInfoVisible(true);
    //   } catch (e) {
    //     //
    //   }
    // }
  };

  const toogleMapSize = () => {
    setMapHeight((toggleMap && height) || expandedHeight);
    setToggleMap(!toggleMap);
  };

  const getIcon = (equipment, color) => {
    const iconTypes = {
      Isca: {
        name: 'Pin',
        iconSize: [27, 40],
        iconAnchor: [13, 40],
      },
      Moto: {
        name: 'Moto',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      Carreta: {
        name: 'TruckTrailer',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      Truck: {
        name: 'Truck',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      'Veículo Passeio': {
        name: 'Car',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      Cavalo: {
        name: 'Horse',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
      Bloqueador: {
        name: 'Safeloggy',
        iconSize: [49, 41],
        iconAnchor: [24, 41],
      },
    };

    let icon = iconTypes.Isca;
    if (Object.keys(iconTypes).indexOf(equipment.equipmentType.name) > -1) {
      icon = iconTypes[equipment.equipmentType.name];
    }

    return L.icon({
      iconUrl: require(`assets/icon/${icon.name}_${color}.png`),
      iconSize: icon.iconSize,
      iconAnchor: icon.iconAnchor,
    });
  };

  // returns a list with every Xglobal positions if showXglobal is set to true
  // otherwise just return last event position
  const getPositions = async (equipment) => {

    return new Promise(async (resolve, reject) => {
      if (Object.keys(equipment.lastEvent).length === 0) {
        return [];
      }
      if (equipment.lastEvent.positionIndicator.length === 0) {
        return [];
      }

      const eventConfig = EventsConfig[getEventName(equipment.lastEvent)];

      if (!eventConfig) {
        console.log(equipment.lastEvent)
      }

      let positions = [
        {
          event: equipment.lastEvent,
          position: [equipment.lastEvent.latitude, equipment.lastEvent.longitude],
          icon: getIcon(equipment, eventConfig.iconColor),
          circle: eventConfig.circle,
        },
      ];

      if (showXglobal) {
        positions = [];
        const events = getXGlobalEventNames(equipment);

        for (const eventName of events) {

          const event = equipment[eventName];

          if (event && isPositionValid(event) && equipment[eventName].latitude !== null && equipment[eventName].longitude !== null) {
            positions.push({
              eventName: eventName,
              event: equipment[eventName],
              position: [
                equipment[eventName].latitude,
                equipment[eventName].longitude,
              ],
              icon: getIcon(equipment, EventsConfig[eventName].iconColor),
              circle: EventsConfig[eventName].circle,
            });
          }
        }

        if (equipment.iccid.substring(0, 6) === '895532') { // ALGAR
          positions = positions.filter(event => event.eventName !== 'lastEventEseye')
        } else if (equipment.iccid.substring(0, 6) === '895517') { // ESEYE
          positions = positions.filter(event => event.eventName !== 'lastEventAlgar')
        }

        console.log('Positions: ')
        console.log(positions)

        const { hideFarthestPosition, farthestPositionRay } = await getThreshold()

        if (hideFarthestPosition) {
          if (positions.length === 3) {
            const position1 = {
              lat: parseFloat(positions[0].position[0]),
              long: parseFloat(positions[0].position[1]),
            }

            const position2 = {
              lat: parseFloat(positions[1].position[0]),
              long: parseFloat(positions[1].position[1]),
            }

            const position3 = {
              lat: parseFloat(positions[2].position[0]),
              long: parseFloat(positions[2].position[1]),
            }

            const further = analyzeCoordinates(position1, position2, position3, farthestPositionRay)

            if (further) {
              if (JSON.stringify(further) === JSON.stringify(position1)) {
                positions.splice(0, 1);
              } else if (JSON.stringify(further) === JSON.stringify(position2)) {
                positions.splice(1, 1);
              } else if (JSON.stringify(further) === JSON.stringify(position3)) {
                positions.splice(2, 1);
              }
            }

          }
        }

        if (positions.length === 1 && positions[0].circle.color === '#07A39F') { // LORA cases
          if ('lastXglobalEvent' in equipment) {
            positions.push({
              event: equipment.lastXglobalEvent,
              position: [
                equipment.lastXglobalEvent.latitude,
                equipment.lastXglobalEvent.longitude,
              ],
              icon: getIcon(equipment, EventsConfig.lastEventLbsXglobal.iconColor),
              circle: EventsConfig.lastEventLbsXglobal.circle,
            });
          }
        }
      }
      resolve(positions);
      // return positions;
    })
  };

  const getCircle = position => {
    if (position.circle) {
      return L.circle(position.position, {
        radius: position.circle.radius(position.event),
        color: position.circle.color,
      });
    }
  };

  useEffect(() => {
    const map = L.map('map', {
      zoom: 1,
      zoomControl: true,
    });
    // create map
    map.zoomControl.setPosition('bottomright');

    // setup layers
    const layer = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }
    );
    layer.on('loading', () => {
      emitEvent('showGlobalLinearProgress');
    });
    layer.on('load', () => {
      emitEvent('hideGlobalLinearProgress');
    });
    layer.addTo(map);
    setMapInstance(map);
  }, []);

  /* load fences */
  useEffect(() => {
    if (showAllFences) {
      const _fetch = async () => {
        const fences = await get('fence/list');
        setFences(fences);
      };
      _fetch();
    }
  }, []);

  /* load fence by equipment */
  useEffect(() => {
    if (showCurrentEquipmentFences && currentEquipment._id) {
      const _fetch = async () => {
        const fences = await get(
          `fence/get-by-equipment/${currentEquipment._id}`
        );
        setFences(fences);
      };
      _fetch();
    }
  }, [currentEquipment]);

  useEffect(() => {
    if (fences.length > 0 && isFitBoundsFinished) {
      /* remove old layers */
      if (fenceLayers.length > 0) {
        fenceLayers.forEach(_layer => {
          mapInstance.removeLayer(_layer);
        });
      }
      const newLayers = [];
      fences.forEach(fence => {
        const layers = L.geoJSON(fence.geoJSON);
        layers.addTo(mapInstance);
        newLayers.push(layers);
      });
      setFenceLayers(newLayers);
    }
  }, [fences, isFitBoundsFinished, mapInstance]);

  // re renders when map height is changed
  useEffect(() => {
    if (mapInstance) {
      mapInstance.invalidateSize();
    }
  }, [mapHeight]);

  // setup markers/pins/circles
  useEffect(() => {
    // delete old markers and circles

    const fn = async () => {
      if (markers.length > 0) {
        markers.forEach(marker => mapInstance.removeLayer(marker));
        circles.forEach(circle => circle.removeFrom(mapInstance));
      }

      if (equipments.length > 0) {
        const newMarkers = [];
        const newCircles = [];

        for (const equipment of equipments.filter(equip => equip.lastEvent)) {
          const positions = await getPositions(equipment);
          for (const position of positions) {
            try {
              if (position.event && isPositionValid(position.event)) {
                const circle = getCircle(position);
                if (circle) {
                  circle.addTo(mapInstance);
                  newCircles.push(circle);
                }
                const mark = L.marker(position.position, {
                  icon: position.icon,
                });
                mark
                  .addTo(mapInstance)
                  .on('click', e => handleMarkClick(equipment, position.event, e));
                newMarkers.push(mark);
              } else {
                console.log(
                  `Equipment: ${equipment.imei} with position ${JSON.stringify(
                    position.position
                  )} is not valid`
                );
              }
            } catch (err) {
              console.log(err);
              console.log(`Equipment: ${equipment.imei} lat/long is not valid`);
            }
          }
        }

        if (newMarkers.length > 0) {
          // center map
          const bounds = new L.LatLngBounds(
            newMarkers.map(mark => [mark.getLatLng().lat, mark.getLatLng().lng])
          );
          mapInstance.fitBounds(bounds.pad(0.5), { maxZoom: 15 });
          setIsFitBoundsFinished(true);
        }
        setMarkers(newMarkers);
        setCircles(newCircles);
      }
    }

    fn();

  }, [equipments, showXglobal]);

  useEffect(() => {
    if (currentEquipment.lastEvent) {
      setCurrentHeaderColor(
        EventsConfig[getEventName(currentEquipment.lastEvent)].headerColor
      );
    }
  }, [currentEquipment]);

  return (
    <div className={classes.root} style={{ ...containerStyle }}>
      <div style={{ height: mapHeight }}>
        <div id="map" className={props.mapClass || classes.map} />
      </div>

      <ModalShowLORAInfo
        isVisible={modalShowLORAInfoVisible}
        setIsVisible={setModalShowLORAInfoVisible}
        data={loraEventInfo}
      />

      {showInfo && (
        <Fade in style={{ transitionDelay: `100ms` }}>
          <InfoCard
            // title={getPositionIndicator(currentEquipment.lastEvent)}
            title={getPositionIndicator(currentEquipment.lastEvent) === 'XGlobal' ? window.location.href.includes(process.env.REACT_APP_SECONDARY_URL) ? 'CIM' : 'XGlobal' : getPositionIndicator(currentEquipment.lastEvent)}
            onCloseClick={() => setShowInfo(false)}
            detailURI={`/detalhes-equipamento/${currentEquipment._id}`}
            headerColor={currentHeaderColor}
          >
            <div style={{ display: 'inline-flex' }}>
              <InfoCardField label="imei" value={currentEquipment.shortImei} />
              <InfoCardField
                label="placa / lp"
                value={currentEquipment.vehicle}
                style={{ marginLeft: 45 }}
              />
            </div>
            <InfoCardField
              label="empresa"
              value={
                currentEquipment.currentCompany &&
                currentEquipment.currentCompany.name
              }
              style={{ marginTop: 10 }}
            />
            <InfoCardField
              label="local"
              value={
                // currentEquipment.lastEvent && currentEquipment.lastEvent.local
                currentEquipment.lastEvent &&
                  'isInsideReferencePoint' in currentEquipment.lastEvent &&
                  currentEquipment.lastEvent.isInsideReferencePoint
                  ? currentEquipment.lastEvent.referencePointLocal
                  : currentEquipment.lastEvent.local
              }
              style={{ marginTop: 10 }}
            />
            <div style={{ display: 'inline-flex' }}>
              <InfoCardField
                label="STATUS"
                value={getStatus(currentEquipment, true)}
                style={{ marginTop: 10 }}
              />
              {/* console.log(currentEquipment) */}
              {/* {currentEquipment.handlingStatus === 'emergency' && ( */}
              {(currentEquipment.handlingStatus === 'emergency' ||
                currentEquipment.handlingStatus === 'BEACON' ||
                currentEquipment.handlingStatus === 'RF_ACTIVE' ||
                currentEquipment.handlingStatus === 'suspect') && (currentEquipment.situation !== 'STABLE') && (currentEquipment.currentHandlingStatus !== 'active') &&
                (
                  <InfoCardField
                    label="EMERGÊNCIA"
                    value={getEmergencyLabel(currentEquipment) || ''}
                    style={{ marginTop: 10, marginLeft: 45 }}
                  />
                )}
            </div>
            <InfoCardField
              label="tipo de comunicação"
              value={
                currentEquipment.lastEvent &&
                  currentEquipment.lastEvent.communicationType
                  ? currentEquipment.lastEvent.communicationType
                  : '-'
              }
              style={{ marginTop: 10 }}
            />
          </InfoCard>
        </Fade>
      )}

      {!disableExpand && (
        <IconButton
          disableRipple={true}
          id="expandMapIcon"
          className={classes.toggleMap}
          classes={{ colorSecondary: 'white' }}
          onClick={toogleMapSize}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      )}
    </div>
  );
}
const styles = theme => ({
  root: {
    position: 'relative',
  },
  map: {
    height: '100%',
    borderRadius: theme.border.radius,
    marginBottom: 45,
  },
  toggleMap: {
    right: 36,
    top: 25,
    zIndex: 700,
    position: 'absolute',
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.background.white,
    width: 43,
    height: 43,
  },
});

export default withStyles(styles)(Map);
