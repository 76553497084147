/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable global-require */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
import L from 'leaflet';
import * as moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const getField = (
  instance,
  fieldName,
  defaultValue = '',
  formatter = ''
) => {
  const fieldPath = fieldName.split('.');
  try {
    const value = fieldPath.reduce((a, b) => a[b], instance);
    if (!value && value !== 0) {
      if (typeof defaultValue === 'function') return defaultValue(value);
      return defaultValue;
    }
    if (formatter) {
      return value + formatter;
    }
    return value;
  } catch (err) {
    if (typeof defaultValue === 'function') return defaultValue(undefined);
    return defaultValue;
  }
};

export const buildLogString = (originalData, newData, translateKeys, ignoredKeys = [], trueFalseCast = null, ignoreFrom = false) => {
  try {
    const keys = Object.keys(newData);

    let updatedItemsStr = '';

    for (const key in originalData) {
      if (translateKeys[key] !== undefined) {
        if (!ignoredKeys.includes(key)) {
          if (keys.includes(key)) {
            let isDifferent = false;

            if (typeof originalData[key] === 'object') {
              isDifferent = JSON.stringify(originalData[key]) != JSON.stringify(newData[key]);
            } else {
              isDifferent = originalData[key] != newData[key];
            }

            if (isDifferent) {
              try {
                if (!ignoreFrom) {
                  if (typeof originalData[key] === 'object') {
                    if (originalData[key] !== null) {
                      if (originalData[key].hasOwnProperty('nome')) {
                        updatedItemsStr += ` / ${translateKeys[key]} | De: "${originalData[key].nome}"`;
                      } else if (originalData[key].hasOwnProperty('name')) {
                        updatedItemsStr += ` / ${translateKeys[key]} | De: "${originalData[key].name}"`;
                      }
                    } else {
                      updatedItemsStr += ` / ${translateKeys[key]} | De: ""`;
                    }
                  } else if (trueFalseCast !== null) {
                    if (typeof originalData[key] === "boolean") {
                      updatedItemsStr += ` / ${translateKeys[key]} | De: "${trueFalseCast[originalData[key].toString()]}"`;
                    } else {
                      updatedItemsStr += ` / ${translateKeys[key]} | De: "${originalData[key]}"`;
                    }
                  } else {
                    updatedItemsStr += ` / ${translateKeys[key]} | De: "${originalData[key]}"`;
                  }
                } else {
                  updatedItemsStr += ` / ${translateKeys[key]} |`;
                }

                if (typeof newData[key] === 'object') {
                  if (newData[key] !== null) {
                    if (newData[key].hasOwnProperty('nome')) {
                      updatedItemsStr += ` Para: "${newData[key].nome}"`;
                    } else if (newData[key].hasOwnProperty('name')) {
                      updatedItemsStr += ` Para: "${newData[key].name}"`;
                    }
                  } else {
                    updatedItemsStr += ` Para: ""`;
                  }
                } else if (trueFalseCast !== null) {
                  if (typeof originalData[key] === "boolean") {
                    updatedItemsStr += ` Para: "${trueFalseCast[newData[key].toString()]}"`;
                  } else {
                    updatedItemsStr += ` Para: "${newData[key]}"`;
                  }
                } else {
                  updatedItemsStr += ` Para: "${newData[key]}"`;
                }
              } catch (e) {
                console.log(`Error building edit log string -> ${e}`);
              }
            }
          }
        }
      }
    }

    return updatedItemsStr;
  } catch (e) {
    return '';
  }
};

export const buildTemperatureRangeLogString = (originalData, newData, equipment) => {

  let string = ''

  let castMap = {
    'Sim': 'Ligado',
    'Não': 'Desligado',
    1: 'Baú',
    2: 'Temperatura'
  }

  if (JSON.stringify(originalData) !== JSON.stringify(newData)) {

    if (originalData.temperatureRangeEnabled !== newData.temperatureRangeEnabled) {
      if (newData.temperatureRangeEnabled === 'Sim') {
        string += `Alterou o status do Alarme de Temperatura de tipo ${castMap[newData.temperatureRangeType]} do equipamento ${equipment} de Desligado para Ligado com range de (${newData.temperatureRange.min}º) - (${newData.temperatureRange.max}º)`
      } else {
        string += `Alterou o status do Alarme de Temperatura de tipo ${castMap[originalData.temperatureRangeType]} do equipamento ${equipment} de Ligado para Desligado`
      }
    } else {
      if (newData.temperatureRangeEnabled === 'Sim') {
        string += `Alarme de Temperatura de tipo ${castMap[originalData.temperatureRangeType]} para tipo ${castMap[newData.temperatureRangeType]} com range de (${newData.temperatureRange.min}º) - (${newData.temperatureRange.max}º)`
      }
    }

  }

  return string
}

export const getYesterdayAndToday = () => {
  const today = new Date();

  let yyyy = today.getFullYear();
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let dd = String(today.getDate()).padStart(2, '0');

  const todayFormatted = `${yyyy}-${mm}-${dd}`;

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  yyyy = yesterday.getFullYear();
  mm = String(yesterday.getMonth() + 1).padStart(2, '0');
  dd = String(yesterday.getDate()).padStart(2, '0');

  const yesterdayFormatted = `${yyyy}-${mm}-${dd}`;

  return { yesterdayFormatted, todayFormatted };
};

export const getYesterdayAndTodayToDatePicker = () => {
  const today = moment().endOf('day');

  const yesterday = moment().subtract(2, 'days').startOf('day');

  return { yesterday, today };
};

export const formatTemperature = (instance, fieldName) => {
  const value = getField(instance, fieldName);
  if (!value) {
    return 'Indisponível';
  }
  if (value > 0) {
    return `+${value} Cº`;
  }
  return `${value} Cº`;
};

export const boolToString = (
  value,
  truthy = 'Sim',
  falsy = 'Não',
  defaultValue = ''
) => {
  if (value === '' || value === undefined) {
    return defaultValue;
  }
  return value ? truthy : falsy;
};

export const isRfOn = event => {
  if (!event) return 'Indisponível';
  // if('positionIndicator' in event && event.positionIndicator[0] === 'LORA') return 'Indisponível'
  if (event.protocolVersion === 'V2') {
    return (
      ((event.status.transmitter434Mhz || event.status.transmitter928Mhz) &&
        'Ligado') ||
      'Desligado'
    );
  }
  return (event.rfTriangulation && 'Ligado') || 'Desligado';
};

export const isPositionValid = event => {
  const positionIndicator = getPositionIndicator(event);
  if (event.isPositionCellID && !event.isPositionValid) {
    return false;
  }
  if (event.latitude === '0' && event.longitude === '0') {
    return false;
  }
  if (positionIndicator === 'GPS' && !event.isPositionValid) return false;

  return true;
};

export const getPositionIndicator = (event, useFirst = false) => {
  if (event && event.isLoraPosition) return 'LORA';

  if (event && event.positionIndicator && event.positionIndicator.length > 0) {
    /* the only way we know for sure this event is CellID... */
    if (event.isPositionCellID) {
      return 'CellID';
    }

    if (event.positionIndicator[0] === 'LBS' && !useFirst) {
      if (event.positionIndicator[1] === 'Quectel') return `XGlobal`;
      return event.positionIndicator[1];
    }
    if (event.positionIndicator[0] === 'Eseye' || event.positionIndicator[0] === 'Algar') {
      return 'XGlobal 2';
    }

    const response = event.positionIndicator[0];
    return response;
  }
};

export const getEventName = event => {
  // console.log(event);
  const position = getPositionIndicator(event);

  if (!position) return '';
  return {
    XGlobal: 'lastEventLbsXglobal',
    Google: 'lastEventLbsGoogle',
    CellID: 'lastEventLbsCellID',
    // 'XGlobal 2': 'lastEventEseye',
    'XGlobal 2': 'lastEventAlgar',
    GPS: 'lastEventGps',
    LORA: 'lastEventLORA',
    'LBS+': 'lastEventLbsPlus'
    // 'Safeloggy': 'lastEvent',
  }[position];
};

export const getBatteryStatus = event => (event && event.status
  ? event.protocolVersion !== 'V2'
    ? !!event.batteryStatus
    : !!event.status.charging
  : undefined);

export const getStatus = (equipment, testPoff = false) => {
  // console.log(equipment);
  // TODO: Refatorar essa função criando um objeto global que vai setar os status.

  if (testPoff) {
    if (equipment.poffCommands && equipment.poffCommands.length) {
      if (equipment.poffCommands[0].status === 'SCHEDULED') {
        return 'Aguardando ativação';
      }
    }
  }
  if (equipment.lastEvent) {
    if ((equipment.lastEvent.status.transmitter434Mhz || equipment.lastEvent.status.transmitter928Mhz) && equipment.situation === 'STABLE') {
      return 'RF Ativo';
    }
  }

  if (equipment.currentHandlingStatus === 'active' && equipment.handlingStatus === 'emergency') {
    return {
      suspect: 'Em suspeita',
      emergency: 'Em emergência',
      active: 'Estável',
    }[equipment.handlingStatus];
  }

  return {
    suspect: 'Em suspeita',
    emergency: 'Em emergência',
    active: 'Estável',
    // }[equipment.handlingStatus];
  }[equipment.currentHandlingStatus];
};

export const minutesToTimeString = (minutes, defaultValue = '00:00') => {
  let tString = defaultValue;

  if (minutes) {
    if (minutes.match(/\d\d:\d\d$/)) {
      return minutes;
    }

    const _minutes = parseInt(minutes, 10);
    const _hours = Math.floor(_minutes / 60);
    const h = `${_hours}`.padStart(2, '0');
    const m = `${_minutes - _hours * 60}`.padStart(2, '0');
    tString = `${h}:${m}`;
  }
  return tString;
};

export const addPointsToMap = (map, points, onClick) => {
  const defaultIcon = L.icon({
    iconUrl: require('assets/icon/Pin_blue.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const cellIdIcon = L.icon({
    iconUrl: require('assets/icon/Pin_CellId.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const GPSIcon = L.icon({
    iconUrl: require('assets/icon/Pin_GPS.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const LBSIcon = L.icon({
    iconUrl: require('assets/icon/Pin_LBS.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const LoraIcon = L.icon({
    iconUrl: require('assets/icon/Pin_LORA.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const LBSPlusIcon = L.icon({
    iconUrl: require('assets/icon/Pin_LBSPlus.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const startIcon = L.icon({
    iconUrl: require('assets/icon/Pin_green.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const endIcon = L.icon({
    iconUrl: require('assets/icon/Pin_red.png'),
    iconSize: [27, 40],
    iconAnchor: [13, 40],
  });

  const visiblePoints = {};
  const KEY_INDEXES = { first: 0, last: points.length - 1 };
  (points || []).forEach((p, index) => {
    const key = `${p.latitude},${p.longitude}`;
    if (visiblePoints[key]) {
      visiblePoints[key] = {
        data: [...visiblePoints[key].data, p],
        isLast: index === KEY_INDEXES.last,
        isFirst: visiblePoints[key].isFirst,
      };
    } else {
      visiblePoints[key] = {
        data: [p],
        isFirst: index === KEY_INDEXES.first,
        isLast: index === KEY_INDEXES.last,
      };
    }
  });

  const markers = [];
  Object.keys(visiblePoints).forEach(key => {
    const coord = key.split(',');
    const value = visiblePoints[key];

    let icon = null

    if (value.isFirst) {
      icon = startIcon
    } else if (value.isLast) {
      icon = endIcon
    } else {

      if (value.data[0].hasOwnProperty('positionIndicator') && value.data[0]['positionIndicator'].length > 0) {
        switch (value.data[0]['positionIndicator'][0]) {
          case 'LBS':
            icon = LBSIcon
            break

          case 'CellID':
            icon = cellIdIcon
            break

          case 'GPS':
            icon = GPSIcon
            break

          case 'GPS':
            icon = LoraIcon
            break

          case 'LBS+':
            icon = LBSPlusIcon
            break

          default:
            icon = defaultIcon
            break
        }
      } else {
        icon = defaultIcon
      }
    }

    const m = L.marker(coord, {
      icon: icon,
    });

    markers.push(m);
    m.on('click', e => {
      onClick(value);
    });
    m.addTo(map);
  });
  return markers;
};

export const layerIsValid = (layer) => {
  if (layer) {
    if (Object.keys(layer).length > 0) {
      if ('bounds' in layer && layer.bounds.length === 0) {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }

  return true;
}